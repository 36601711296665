export const adminRoles = ["superadmin", "manager", "support"] as const;

export type AdminRole = (typeof adminRoles)[number];

export const adminCheckPermissions = ({
  adminRoles,
  role,
}: {
  adminRoles: AdminRole[];
  role?: AdminRole;
}) => {
  // superadmin -> access to all admin routes
  if (role === "superadmin") {
    return adminRoles.includes("superadmin");
  }

  // manager -> access to all admin routes except superadmin routes
  if (role === "manager") {
    return adminRoles.includes("superadmin") || adminRoles.includes("manager");
  }

  // support -> access to all admin routes except superadmin and manager routes
  if (role === "support") {
    return (
      adminRoles.includes("superadmin") ||
      adminRoles.includes("manager") ||
      adminRoles.includes("support")
    );
  }

  // if role is not defined, check if adminRoles includes "manager" role
  // default manager role is required for all admin users
  return adminRoles.includes("superadmin") || adminRoles.includes("manager");
};
